import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Contact from "../components/contact"
import Seo from "../components/seo"
import { val }  from '../components/cssvalue'
import {MainWrap, TablePlans, ListSmall, AreaSmall} from '../pages/web.js'



// STYLE
const TablePlansOperate = styled(TablePlans)`
  margin-bottom: 30px;
  th {
    &:nth-child(2) {
      widtH: 120px;
    }
    &:nth-child(3) {
      widtH: 120px;
    }
  }
`;


const SectionDetail = styled.section`
`;

const SectionArticlePrice = styled.section`
`;
const Operate = ({data, location}) => {
  return (
    <Layout location={location}>
      <Seo
        title="デザイン価格一覧 名刺・パンフレット・会社案内・チラシ"
        description="神奈川でホームページ作成TAN-ZENTのデザイン詳細です。"
        pagepath={location.pathname}
      />
      <MainWrap>
        <div className="-jacket">
          <h1>グラフィック デザイン価格一覧 <br className="sp" />詳細ページ</h1>
          <p className="lead">
            ホームページだけではなく、さまざまなデザインも経験20年以上のデザイナーが対応いたします。<br />
            名刺・ポスター・パンフレット・会社案内・チラシなど紙のデザインのほか、商品パッケージやブランドロゴ、店舗やブランドの総合デザイン・プロデュースもご相談ください。
          </p>

            <TablePlansOperate className="-option">
              <caption>グラフィックデザイン費用</caption>
              <thead>
                <tr>
                  <th>項目</th>
                  <th>価格</th>
                  <th>内容</th>
                </tr>
              </thead>
              <tbody className="table-pluns-system">
                <tr>
                  <th>名刺デザイン</th>
                  <td>11,000円〜</td>
                  <td>名刺、表のみ1案のデザイン費</td>
                </tr>
                <tr>
                  <th>カード・お礼状等</th>
                  <td>22,000円〜</td>
                  <td>クリスマスカード、お礼状等A5サイズ目安のデザイン費</td>
                </tr>
                <tr>
                  <th>チラシ・フライヤーデザイン</th>
                  <td>55,000円〜</td>
                  <td>A4サイズ1案のデザイン費</td>
                </tr>
                <tr>
                  <th>ポスターデザイン</th>
                  <td>110,000円〜</td>
                  <td>A3サイズ1案のデザイン費</td>
                </tr>
                <tr>
                  <th>パンフレット</th>
                  <td>110,000円〜</td>
                  <td>A4二つ折り両面のデザイン費</td>
                </tr>
                <tr>
                  <th>会社案内・カタログ等の冊子</th>
                  <td>275,000円〜</td>
                  <td>8ページのカタログデザイン費</td>
                </tr>
                <tr>
                  <th>ロゴ作成</th>
                  <td>55,000円〜</td>
                  <td>1案のみの価格。※</td>
                </tr>
                <tr>
                  <th>イラスト・キャラクター作成</th>
                  <td>55,000円〜</td>
                  <td>1点のみ</td>
                </tr>
                <tr>
                  <th>アートディレクション費</th>
                  <td>55,000円〜</td>
                  <td>デザイン・撮影等で別途アートディレクションが必要な場合にかかる費用です。御社内や外部のデザイナーさんがデザインをされる場合等。</td>
                </tr>
                <tr>
                  <th>総合アートプロデュース</th>
                  <td>220,000円〜</td>
                  <td>ブランドや店舗の総合アートプロデュース費用です。</td>
                </tr>
              </tbody>
            </TablePlansOperate>
            <AreaSmall className="-jacket" id="small">
              <h2 className="title">補足・注意事項</h2>
              <ListSmall>
                <li>※価格に紙や印刷代は含まれておりません。印刷物の納品をご希望の場合は別途お見積もりをさせていただきます。</li>
                <li>※価格にはデザインデータ代金は含まれておりません。</li>
                <li>※作成した成果物の著作権は制作者に帰属しますが、ロゴデザインのみ基本料金にて著作権譲渡契約をさせていただきます。</li>
              </ListSmall>
              <p className="-p">
                ※価格はすべて税込みです。
              </p>
            </AreaSmall>
        </div>
      </MainWrap>
      <Contact />
    </Layout>
  )
}
export default Operate
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
  }
`
